import React, { useState } from "react";
import { Footer } from "../navbar/Footer";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

const Philosophy = () => {
  return (
    <div>
      <div
        className="page-title dark-background"
        style={{
          backgroundImage: "url(assets/img/Bannerimages/PHILOSOPHY.jpg)",
        }}
      >
        <div className="container position-relative">
          <h1 className="works-banner" >Philosophy</h1>
          <nav className="breadcrumbs">
            {/* <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Philosophy</li>
            </ol> */}
          </nav>
        </div>
      </div>
      <div className="container mt-5 philosophy">
        <h2>PHILOSOPHY</h2>
        <p>
          The Aim is to seek contemporaneous connection in a heterogeneous
          environment that is always evolving. It is essential to our efforts to
          reintegrate traditional wisdom into building while honoring natural
          resources and finding indigenous brilliance.
        </p>
      </div>

      <div className="container mt-5 philosophy">
        <div className="row">
          
        <div className="col-lg-6">
        <h2>WHAT IS VOGELKOP ?</h2>
        <video width="320" height="240" controls>
        <source src="/assets/img/about/Whatisvogelkop.mp4" type="video/mp4" />
      </video>
        </div>
        <div className="col-lg-6">
        
        <div className="video-container youtube-video">
        <iframe
  className="vogelkop-video"
  width="560"
  height="315"
  src="https://www.youtube.com/embed/E1zmfTr2d4c?start=75"
  title="Vogelkop Video"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowFullScreen
></iframe>

        </div>
        </div>
        </div>
      </div>

      <div className="container mt-5 philosophy">
        <h2>WHY VOGELKOP</h2>
        <p>
        At Vogelkop Architects, we draw inspiration from the most unlikely of sources—the Vogelkop Bowerbird. This remarkable bird, native to the dense forests of New Guinea, is renowned for its architectural prowess. Just as we at Vogelkop Architects approach each project with a blend of creativity, precision, and respect for our environment, the Vogelkop Bowerbird showcases similar attributes in its natural habitat.
        </p>
      </div>

      <div className="container mt-5 philosophy">
        <h3>The Art of Attraction</h3>
        <p>
        We understand the importance of aesthetics in creating spaces that captivate and inspire. Just as the bowerbird carefully selects and arranges materials to create a harmonious environment, we choose our materials and design elements with care, ensuring that each project we undertake resonates with beauty and functionality.
        </p>
      </div>

      <div className="container mt-5 philosophy">
        <h3>Function Meets Form
        </h3>
        <p>
        While the Vogelkop Bowerbird’s bower serves a unique purpose in the natural world, its construction principles are surprisingly aligned with architectural design. The bird considers the spatial arrangement, colour contrasts, and overall composition of its bower, ensuring that it not only looks appealing but also functions as an effective display.
        </p>
        <p>
        At Vogelkop Architects, we take a similar approach. Every structure we design is a balance of form and function. We believe that great architecture should not only be visually striking but also serve the needs of its occupants. Our designs are a testament to this philosophy, blending practicality with artistic expression.
        </p>
      </div>

      <div className="container mt-5 philosophy">
        <h3>Sustainable Design
        </h3>
        <p>
        One of the most remarkable aspects of the Vogelkop Bowerbird’s construction process is its sustainability. The bird uses natural and locally-sourced materials, working within the constraints of its environment. This approach ensures that its creations are not only beautiful but also eco-friendly.

        </p>
        <p>
        At Vogelkop Architects, sustainability is at the core of our design ethos. We strive to create buildings that respect and enhance their surroundings, using sustainable materials and practices that minimise environmental impact. Like the Vogelkop Bowerbird, we believe that true beauty lies in harmony with nature.
        </p>
      </div>


      <div className="container mt-5 mb-5 philosophy">
        <h2>ABOUT US</h2>
        <p>
          Vogelkop architects is a collaborative studio practice led by
          Bhuvanesh and Hari Haran, with assistance from a group of young
          professionals. We employ an integrated design methodology that
          encompasses interior design, landscape architecture, and architectural
          design. Through extensive studies, explorations, study models, and
          sketches, the design process aims to reconcile the multiple paradoxes
          between customer wants and affordability, site conditions and
          programming dictates.
        </p>
      </div>

      {/* <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6 accordin-content">
          Vogelkop architects is a collaborative studio practice led
          </div>
          <div className="col-lg-6 accordin-number">
           03
          </div>
        </div>
        <hr></hr>
      </div> */}
      <Footer1 />
    </div>
  );
};

export default Philosophy;
