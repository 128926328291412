import React from 'react'

const Footer1 = () => {
  return (
    <div>
        <div className="copyright text-center  Footer-2">
          <p>© <span>Copyright</span> <strong className="px-1 sitename">VOGELKOP</strong> <span>All Rights Reserved</span></p>
        </div>
    </div>
  )
}

export default Footer1