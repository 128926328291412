import React from "react";
import { Footer } from "../navbar/Footer";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

const Team = () => {
  return (
    <div>
      <div
        className="page-title dark-background"
        style={{ backgroundImage: "url(assets/img/Bannerimages/TEAM.jpg)" }}
      >
        <div className="container position-relative">
          <h1 className="works-banner" >Team</h1>
          <nav className="breadcrumbs">
            {/* <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Team</li>
            </ol> */}
          </nav>
        </div>
      </div>

      <section id="team" className="team section">
        <div className="container section-title">
          <h2>Team</h2>
        </div>

        <div className="container">
          <div className="row gy-5">
           

            

            <div className="col-lg-4 col-md-6 member">
              <div className="member-img">
                <img
                  src="assets/img/team/BHUVANESH.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <Link to="https://x.com/BhuvaneshV15/" target="blank" >
                    <i className="bi bi-twitter-x"></i>
                  </Link>
                  <Link to="https://www.instagram.com/bhuvanesh.kpv?igsh=MTEwemd6cTJybnkzNw==" target="blank" >
                    <i className="bi bi-instagram"></i>
                  </Link>
                  <Link to="https://www.linkedin.com/in/bhuvanesh-v-02a146224" target="blank">
                    <i className="bi bi-linkedin"></i>
                  </Link>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Ar.BHUVANESH.V</h4>
                <span>M.arch. CO-FOUNDER</span>
              </div>
            </div>

           

            <div className="col-lg-4 col-md-6 member">
              <div className="member-img">
                <img
                  src="assets/img/team/HARIHARAN.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <Link to="https://x.com/ArHariharan10/" target="blank">
                    <i className="bi bi-twitter-x"></i>
                  </Link>
                  {/* <Link to="#">
                    <i className="bi bi-facebook"></i>
                  </Link> */}
                  <Link to="https://www.instagram.com/architekt_h?igsh=MXJyMGptazgzd3c2Mg==" target="blank">
                    <i className="bi bi-instagram"></i>
                  </Link>
                  <Link to="https://www.linkedin.com/in/hariharan-b-74a129208" target="blank">
                    <i className="bi bi-linkedin"></i>
                  </Link>
                </div>
              </div>
              <div className="member-info text-center">
                <h4> Ar.HARIHARAN.B</h4>
                <span> B.Arch.  CO-FOUNDER.</span>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 member">
              <div className="member-img">
                <img
                  src="assets/img/team/SHAMRITHA.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="social">
                  <Link to="#">
                    <i className="bi bi-twitter-x"></i>
                  </Link>
                  <Link to="#">
                    <i className="bi bi-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="bi bi-instagram"></i>
                  </Link>
                  <Link to="#">
                    <i className="bi bi-linkedin"></i>
                  </Link>
                </div>
              </div>
              <div className="member-info text-center">
                <h4>Ar.SHARMITHA</h4>
                <span>  B.Arch. ARCHITECT.
                </span>
              </div>
            </div>

            
          </div>
        </div>
      </section>
      <Footer1 />
    </div>
  );
};

export default Team;
