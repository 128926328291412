import React, { useEffect, useState } from "react";
import Isotope from "isotope-layout";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

const Works = () => {
  const [filterKey, setFilterKey] = useState("*");

  useEffect(() => {
    // Initialize Isotope
    const iso = new Isotope(".workgrid", {
      itemSelector: ".workelement-item",
      layoutMode: "fitRows",
    });

    // Apply filter based on filterKey
    iso.arrange({ filter: filterKey === "*" ? "*" : `.${filterKey}` });

    // Cleanup on unmount
    return () => iso.destroy();
  }, [filterKey]);

  const handleFilterKeyChange = (key) => () => {
    setFilterKey(key);
  };

  return (
    <div>
      <div
        className="page-title dark-background"
        style={{ backgroundImage: "url(assets/img/Bannerimages/WORKS.png)" }}
      >
        <div className="container position-relative">
          <h1 className="works-banner" >Works</h1>
          <nav className="breadcrumbs">
            {/* <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Works</li>
            </ol> */}
          </nav>
        </div>
      </div>
      <div className="workpage-main mt-5 mb-5">
        <div className="workbutton-group filters-button-group">
          <button
            className={`workbutton ${filterKey === "*" ? "is-checked" : ""}`}
            onClick={handleFilterKeyChange("*")}
          >
            All
          </button>
          <button
            className={`workbutton ${
              filterKey === "residential" ? "is-checked" : ""
            }`}
            onClick={handleFilterKeyChange("residential")}
          >
            Residential
          </button>
          <button
            className={`workbutton ${
              filterKey === "housing" ? "is-checked" : ""
            }`}
            onClick={handleFilterKeyChange("housing")}
          >
            Housing
          </button>
          <button
            className={`workbutton ${
              filterKey === "industrial" ? "is-checked" : ""
            }`}
            onClick={handleFilterKeyChange("industrial")}
          >
            Industrial
          </button>
          <button
            className={`workbutton ${
              filterKey === "interiors" ? "is-checked" : ""
            }`}
            onClick={handleFilterKeyChange("interiors")}
          >
            Interiors
          </button>
          <button
            className={`workbutton ${
              filterKey === "hospitality" ? "is-checked" : ""
            }`}
            onClick={handleFilterKeyChange("hospitality")}
          >
            Hospitality
          </button>
        </div>

        <div className="workgrid">
          <Link to="/Residential">
            <div className="workelement-item transition residential">
              <div className="workitem-image">
                <img src="assets/img/RES/render-01.jpg" alt=""></img>
              </div>
              <h1>UD-YA-NA</h1>
              <h2>residential</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Residential1">
            <div className="workelement-item transition residential">
              <div className="workitem-image">
                <img src="/assets/img/residential1/image1.jpg" alt=""></img>
              </div>
              <h1>KUDIL</h1>
              <h2>residential</h2>

              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Residential2">
            <div className="workelement-item transition residential">
              <div className="workitem-image">
                <img src="/assets/img/residential2/res2-5.jpg" alt=""></img>
              </div>
              <h1>TEMPLE VIEW VILLA</h1>
              <h2>residential</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Residential3">
            <div className="workelement-item transition residential">
              <div className="workitem-image">
                <img
                  src="/assets/img/residential3/res3-2.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                ></img>
              </div>
              <h1>IN-OU RESIDENCE</h1>
              <h2>residential</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Residential4">
            <div className="workelement-item transition residential">
              <div className="workitem-image">
                <img src="/assets/img/residential4/res4-1.png" alt=""></img>
              </div>
              <h1>LAKSHMI NIVAS</h1>
              <h2>residential</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Residential5">
            <div className="workelement-item transition residential">
              <div className="workitem-image">
                <img
                  src="/assets/img/residential5/res5-HEADER.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                ></img>
              </div>
              <h1>ANGLE DWELL</h1>
              <h2>residential</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Residential6">
            <div className="workelement-item transition residential">
              <div className="workitem-image">
                <img src="/assets/img/residential6/res6-2.jpg" alt=""></img>
              </div>
              <h1>FARM VILLA</h1>
              <h2>residential</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Residential7">
            <div className="workelement-item transition residential">
              <div className="workitem-image">
                <img src="/assets/img/residential7/res7-3.png" alt=""></img>
              </div>
              <h1>GIRIJA’S RESIDENCE</h1>
              <h2>residential</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Residential8">
            <div className="workelement-item transition residential"id="work-element" >
              <div className="workitem-image">
                <img src="/assets/img/residential8/res8-6.jpg" alt=""></img>
              </div>
              <h1>RAMAKRISHNAN RESIDENCE</h1>
              <h2>residential</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>

          <div className="workelement-item transition industrial">
            <div className="workitem-image">
              <img src="assets/img/Interior/HEADER.jpg" alt=""></img>
            </div>
            <h1>Industrial</h1>
            <h2>industrial</h2>
            <h3 className="workitem-title">
              This is a house for the Dhila family which engages in agriculture
              and dairy farming.
            </h3>
          </div>
          <Link to="/Interior1">
            <div className="workelement-item transition interiors" id="work-element">
              <div className="workitem-image">
                <img src="/assets/img/interior1/int1-2.JPG" alt=""></img>
              </div>
              <h1>SANTHOSH’S RESIDENCE </h1>
            <h2>interior</h2>
              
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>
          <Link to="/Interior2">
            <div className="workelement-item transition interiors">
              <div className="workitem-image">
                <img
                  src="/assets/img/interior2/int2-8.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                ></img>
              </div>
              <h1>VISVAJA</h1>
            <h2>interior</h2>
              <h3 className="workitem-title">
                This is a house for the Dhila family which engages in
                agriculture and dairy farming.
              </h3>
            </div>
          </Link>

          <div className="workelement-item transition hospitality">
            <div className="workitem-image">
              <img src="assets/img/sub-hero-banner.jpg" alt=""></img>
            </div>
            <h1>Hospitality</h1>
            <h2>Hospitality</h2>

            <h3 className="workitem-title">
              This is a house for the Dhila family which engages in agriculture
              and dairy farming.
            </h3>
          </div>

          <div className="workelement-item transition housing">
            <div className="workitem-image">
              <img src="assets/img/sub-hero-banner.jpg" alt=""></img>
            </div>
            <h1>Housing</h1>
            <h2>Housing</h2>
            
            <h3 className="workitem-title">
              This is a house for the Dhila family which engages in agriculture
              and dairy farming.
            </h3>
          </div>
        </div>
      </div>
      <Footer1 />
    </div>
  );
};

export default Works;
