import React from 'react'
import { Link } from 'react-router-dom';
import {Footer} from '../navbar/Footer';
import Footer1 from '../navbar/Footer1';

;

const About = () => {


  
  return (
    <div>
      <div className="page-title dark-background" style={{backgroundImage: 'url(assets/img/sub-hero-banner.jpg)'}}>
      <div className="container position-relative">
        <h1>About</h1>
        <nav className="breadcrumbs">
          <ol>
            <li><Link to="/">Home</Link></li>
            <li className="current">About</li>
          </ol>
        </nav>
      </div>
    </div>


<section class="section_all bg-light" id="about" >
            <div class="container" >
                <div class="row" >
                    <div class="col-lg-12">
                        <div class="section_title_all text-center" >
                            <h3 class="font-weight-bold">Welcome To <span class="text-custom">VOGELKOP</span></h3>
                            <p class="section_subtitle mx-auto text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br/>Lorem Ipsum has been the industry's standard dummy text.</p>
                            <div class="">
                                <i class=""></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <h4 class="text_custom font-weight-bold">Lorem Ipsum is simply dummy text</h4>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Lorem Ipsum is simply dummy text of the printing industry.</h4>
                            <p class="text-muted mt-3">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>

                            <p class="text-muted mt-3"> Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src="https://i.ibb.co/qpz1hvM/About-us.jpg" alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-lg-4">
                        <div class="about_content_box_all mt-3">
                            <div class="about_detail text-center">
                                <div class="about_icon">
                                    <i class="fas fa-pencil-alt"></i>
                                </div>
                                <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Creative Design</h5>
                                <p class="edu_desc mt-3 mb-0 text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="about_content_box_all mt-3">
                            <div class="about_detail text-center">
                                <div class="about_icon">
                                    <i class="fab fa-angellist"></i>
                                </div>
                                <h5 class="text-dark text-capitalize mt-3 font-weight-bold">We make Best Result</h5>
                                <p class="edu_desc mb-0 mt-3 text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="about_content_box_all mt-3">
                            <div class="about_detail text-center">
                                <div class="about_icon">
                                    <i class="fas fa-paper-plane"></i>
                                </div>
                                <h5 class="text-dark text-capitalize mt-3 font-weight-bold">best platform </h5>
                                <p class="edu_desc mb-0 mt-3 text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<section id="team" className="team section">


<div className="container section-title" >
  <h2>Team</h2>
  <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
</div>

<div className="container" >

  <div className="row gy-5" >

    <div className="col-lg-4 col-md-6 member" >
      <div className="member-img">
        <img src="assets/img/team/team-1.jpg" className="img-fluid" alt=""/>
        <div className="social">
          <Link to="#"><i className="bi bi-twitter-x"></i></Link>
          <Link to="#"><i className="bi bi-facebook"></i></Link>
          <Link to="#"><i className="bi bi-instagram"></i></Link>
          <Link to="#"><i className="bi bi-linkedin"></i></Link>
        </div>
      </div>
      <div className="member-info text-center">
        <h4>Walter White</h4>
        <span>Chief Executive Officer</span>
        <p>Aliquam iure quaerat voluptatem praesentium possimus unde laudantium vel dolorum distinctio dire flow</p>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 member" >
      <div className="member-img">
        <img src="assets/img/team/team-2.jpg" className="img-fluid" alt=""/>
        <div className="social">
          <Link to="#"><i className="bi bi-twitter-x"></i></Link>
          <Link to="#"><i className="bi bi-facebook"></i></Link>
          <Link to="#"><i className="bi bi-instagram"></i></Link>
          <Link to="#"><i className="bi bi-linkedin"></i></Link>
        </div>
      </div>
      <div className="member-info text-center">
        <h4>Sarah Jhonson</h4>
        <span>Product Manager</span>
        <p>Labore ipsam sit consequatur exercitationem rerum laboriosam laudantium aut quod dolores exercitationem ut</p>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 member" >
      <div className="member-img">
        <img src="assets/img/team/team-3.jpg" className="img-fluid" alt=""/>
        <div className="social">
          <Link to="#"><i className="bi bi-twitter-x"></i></Link>
          <Link to="#"><i className="bi bi-facebook"></i></Link>
          <Link to="#"><i className="bi bi-instagram"></i></Link>
          <Link to="#"><i className="bi bi-linkedin"></i></Link>
        </div>
      </div>
      <div className="member-info text-center">
        <h4>William Anderson</h4>
        <span>CTO</span>
        <p>Illum minima ea autem doloremque ipsum quidem quas aspernatur modi ut praesentium vel tque sed facilis at qui</p>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 member" >
      <div className="member-img">
        <img src="assets/img/team/team-4.jpg" className="img-fluid" alt=""/>
        <div className="social">
          <Link to="#"><i className="bi bi-twitter-x"></i></Link>
          <Link to="#"><i className="bi bi-facebook"></i></Link>
          <Link to="#"><i className="bi bi-instagram"></i></Link>
          <Link to="#"><i className="bi bi-linkedin"></i></Link>
        </div>
      </div>
      <div className="member-info text-center">
        <h4>Amanda Jepson</h4>
        <span>Accountant</span>
        <p>Magni voluptatem accusamus assumenda cum nisi aut qui dolorem voluptate sed et veniam quasi quam consectetur</p>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 member" >
      <div className="member-img">
        <img src="assets/img/team/team-5.jpg" className="img-fluid" alt=""/>
        <div className="social">
          <Link to="#"><i className="bi bi-twitter-x"></i></Link>
          <Link to="#"><i className="bi bi-facebook"></i></Link>
          <Link to="#"><i className="bi bi-instagram"></i></Link>
          <Link to="#"><i className="bi bi-linkedin"></i></Link>
        </div>
      </div>
      <div className="member-info text-center">
        <h4>Brian Doe</h4>
        <span>Marketing</span>
        <p>Qui consequuntur quos accusamus magnam quo est molestiae eius laboriosam sunt doloribus quia impedit laborum velit</p>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 member">
      <div className="member-img">
        <img src="assets/img/team/team-6.jpg" className="img-fluid" alt=""/>
        <div className="social">
          <Link to="#"><i className="bi bi-twitter-x"></i></Link>
          <Link to="#"><i className="bi bi-facebook"></i></Link>
          <Link to="#"><i className="bi bi-instagram"></i></Link>
          <Link to="#"><i className="bi bi-linkedin"></i></Link>
        </div>
      </div>
      <div className="member-info text-center">
        <h4>Josepha Palas</h4>
        <span>Operation</span>
        <p>Sint sint eveniet explicabo amet consequatur nesciunt error enim rerum earum et omnis fugit eligendi cupiditate vel</p>
      </div>
    </div>
  </div>

</div>

</section>
<Footer1/>
    </div>
  )
}

export default About;