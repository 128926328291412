import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import "../navbar/Header.css";
import { GoChevronDown } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [hoveredItem, setHoveredItem] = useState("Residential");
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeSubDropdown, setActiveSubDropdown] = useState(null);

  const worksImages = {
    Residential: [
      { src: "/assets/img/RES/render-01.jpg", link: "/residential" },
      { src: "/assets/img/residential1/image1.jpg", link: "/residential1" },
      { src: "/assets/img/residential2/res2-5.jpg", link: "/residential2" },
      { src: "/assets/img/residential3/res3-2.jpg", link: "/residential3" },
      { src: "/assets/img/residential4/res4-1.png", link: "/residential4" },
      {
        src: "/assets/img/residential5/res5-HEADER.jpg",
        link: "/residential5",
      },
      { src: "/assets/img/residential6/res6-2.jpg", link: "/residential6" },
      { src: "/assets/img/residential7/res7-3.png", link: "/residential7" },
      { src: "/assets/img/residential8/res8-6.jpg", link: "/residential8" },
    ],
    Housing: [
      { src: "/assets/img/residential6/res6-2.jpg", link: "/residential6" },
      { src: "/assets/img/residential7/res7-3.png", link: "/residential7" },
      { src: "/assets/img/residential8/res8-6.jpg", link: "/residential8" },
    ],
    Industrial: [
      { src: "/assets/img/interior1/int1-2.JPG", link: "/Interior1" },
    ],
    Interiors: [
      { src: "/assets/img/interior1/int1-2.JPG", link: "/Interior1" },
      { src: "/assets/img/interior2/int2-8.jpg", link: "/Interior2" },
    ],
    Hospitality: [
      { src: "/assets/img/interior1/int1-2.JPG", link: "/Interior1" },
    ],
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setActiveDropdown(null); // Close any open dropdowns when toggling mobile menu
  };

  const handleDropdownClick = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
    setActiveSubDropdown(null); // Reset sub-dropdown when changing main dropdown
  };

  const handleSubDropdownClick = (subDropdown) => {
    if (window.innerWidth <= 991) {
      setActiveSubDropdown(
        activeSubDropdown === subDropdown ? null : subDropdown
      );
    }
  };

  const handleDropdownHover = (item) => {
    if (window.innerWidth > 991) {
      setHoveredItem(item);
    }
  };

  const handleDropdownLeave = () => {
    if (window.innerWidth > 991) {
      setHoveredItem("Residential");
    }
  };

  return (
    <>
      <div
        className={`site-mobile-menu site-navbar-target ${
          isMobileMenuOpen ? "active" : ""
        }`}
        data-spy="scroll"
        data-target=".site-navbar-target"
        data-offset="300"
      >
        <div className="site-mobile-menu-header">
          <div
            className="site-mobile-menu-close mt-3"
            onClick={toggleMobileMenu}
          >
            <span className="icon-close2 js-menu-toggle">
              <FiX />
            </span>
          </div>
        </div>
        <div className="mt-5">
          <ul className="site-menu main-menu js-clone-nav mr-auto d-lg-none">
            <li className="has-children">
              <Link to="/">Home</Link>
            </li>
            <li
              className="has-children"
              onClick={() => handleDropdownClick("about")}
            >
              <Link>About us</Link>
              {activeDropdown === "about" && (
                <ul className="dropdown-menu" style={{ minWidth: "233px" }}>
                  <li>
                    <Link to="/philosophy" className="dropdown-link">
                      <div className="image-card-about-dropdown">
                        <img
                          src="assets/img/about/Philosophy.jpeg"
                          className="about-img"
                          alt="Philosophy"
                        />
                      </div>
                      Philosophy
                    </Link>
                  </li>
                  <li>
                    <Link to="/team" className="dropdown-link">
                      <div className="image-card-about-dropdown">
                        <img
                          src="assets/img/about/team.jpeg"
                          className="about-img"
                          alt="Team"
                        />
                      </div>
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link to="/press" className="dropdown-link">
                      <div className="image-card-about-dropdown">
                        <img
                          src="assets/img/about/press.jpeg"
                          className="about-img"
                          alt="Press"
                        />
                      </div>
                      Press
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li
              className="has-children"
              onClick={() => handleDropdownClick("works")}
            >
              <Link to="/works">Works</Link>
              {activeDropdown === "works" && (
                <ul
                  className="dropdown-menu"
                  style={{
                    minWidth: "233px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {Object.keys(worksImages).map((key) => (
                    <li key={key} onClick={() => handleSubDropdownClick(key)}>
                      /{/* <Link to={`/${key}`} className="dropdown-link"> */}
                      <Link to="/works" className="dropdown-link">
                        {key}
                      </Link>
                      {activeSubDropdown === key && (
                        <div className="image-card">
                          {worksImages[key].map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`${key} ${index}`}
                            />
                          ))}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li className="has-children">
              <Link to="/projects">VA Aesthetics</Link>
            </li>
            <li className="has-children">
              <Link to="/contact">Contact us</Link>
            </li>
          </ul>
        </div>
      </div>
      <header
        className={`site-navbar py-0 py-md-4 site-navbar-target ${
          scrolled ? "scrolled" : ""
        }`}
        role="banner"
      >
        <div className="container-test">
          <div className="logo-test mt-3">
            <h1 className="mb-0 site-logo">
              <Link to="/" className="text-black h2 mb-0">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h1
                      className="sitename"
                      style={{
                        color: scrolled ? "#292974" : "white",
                      }}
                    >
                      VOGELKOP ARCHITECTS
                    </h1>
                    <span
                      className="subtext"
                      style={{
                        color: scrolled ? "#292974" : "white",
                        display: "block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      architecture | interior design | landscape
                    </span>
                  </div>
                  <img
                    src="assets/img/logo.png"
                    alt="Logo"
                    className="logo-img"
                  />
                </div>
              </Link>
            </h1>
          </div>

          <div
            className="pb-3 d-lg-none"
            style={{
              position: "relative",
              top: "3px",
              width: "20px",
              marginLeft: "85px",
            }}
          >
            <button
              className="site-menu-toggle js-menu-toggle"
              onClick={toggleMobileMenu}
            >
              <span className={`icon-menu h3 ${scrolled ? "scrolled" : ""}`}>
                <FaBars />
              </span>
            </button>
          </div>

          <div className="menu-test d-none d-xl-block">
            <nav
              className="site-navigation position-relative text-right d-flex flex-row"
              role="navigation"
            >
              <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                <li className="has-children">
                  <Link
                    to="/"
                    className="nav-link underline default-active"
                    style={{
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "21px",
                      textTransform: "lowercase",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="has-children">
                  <Link
                    className="nav-link underline"
                    to="#"
                    style={{
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "21px",
                      textTransform: "lowercase",
                    }}
                  >
                    About us <GoChevronDown />
                  </Link>
                  <ul
                    className="dropdown-menu"
                    id="about-dropdown"
                    style={{ padding: "0px", minWidth: "0px" }}
                  >
                    <li
                      onMouseEnter={() => handleDropdownHover("about")}
                      onMouseLeave={handleDropdownLeave}
                    >
                      <div className="image-card-about">
                        <Link to="/philosophy">
                          <div className="image-card-about-dropdown">
                            <img
                              src="assets/img/about/Philosophy.jpeg"
                              className="about-img"
                              alt="Philosophy"
                            />
                            <p>Philosophy</p>
                          </div>
                        </Link>
                        <Link to="/team">
                          <div className="image-card-about-dropdown">
                            <img
                              src="assets/img/about/team.jpeg"
                              className="about-img"
                              alt="Team"
                            />
                            <p>Team</p>
                          </div>
                        </Link>
                        <Link to="/press">
                          <div className="image-card-about-dropdown">
                            <img
                              src="assets/img/about/press.jpeg"
                              className="about-img"
                              alt="Press"
                            />
                            <p>Press</p>
                          </div>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>

                <li className="has-children">
                  <Link
                    className="nav-link underline"
                    to="/works"
                    style={{
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "21px",
                      textTransform: "lowercase",
                    }}
                  >
                    Works <GoChevronDown />
                  </Link>
                  <ul className="dropdown-menu" id="works-dropdown">
                    <div className="dropdown-content">
                      <div className="dropdown-list">
                        {Object.keys(worksImages).map((key) => (
                          <li
                            key={key}
                            className="dropdown-item"
                            onMouseEnter={() => handleDropdownHover(key)}
                          >
                            {/* <Link to={`/${key}`} className="dropdown-link"> */}
                            <Link to="/works" className="dropdown-link">
                              {key} <IoIosArrowForward />
                            </Link>
                          </li>
                        ))}
                      </div>
                      <div className="dropdown-images">
                        {worksImages[hoveredItem]?.map((image, index) => (
                          <Link to={image.link} key={index}>
                            <img
                              src={image.src}
                              alt={`${hoveredItem} ${index}`}
                            />
                          </Link>
                        ))}
                      </div>
                    </div>
                  </ul>
                </li>

                <li className="has-children">
                  <Link
                    style={{
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "21px",
                      textTransform: "lowercase",
                    }}
                    className="nav-link underline"
                    to="/projects"
                  >
                    VA Aesthetics
                  </Link>
                </li>
                <li className="has-children">
                  <Link
                    style={{
                      fontWeight: "500",
                      fontSize: "19px",
                      lineHeight: "21px",
                      textTransform: "lowercase",
                    }}
                    className="nav-link underline"
                    to="/contact"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
