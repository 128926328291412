import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Isotope from "isotope-layout";
import GLightbox from "glightbox";
import Swiper from "swiper";
import imagesLoaded from "imagesloaded";
import { Footer } from "../navbar/Footer";
import Footer1 from "../navbar/Footer1";

const Projects = () => {
  useEffect(() => {
    const initIsotope = () => {
      let portfolioContainer = document.querySelector(".isotope-container");
      if (portfolioContainer) {
        let portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: ".portfolio-item",
          layoutMode: "masonry",
        });

        let portfolioFilters = document.querySelectorAll(
          ".portfolio-filters li"
        );

        portfolioFilters.forEach((filter) => {
          filter.addEventListener("click", function (e) {
            e.preventDefault();
            portfolioFilters.forEach((el) => {
              el.classList.remove("filter-active");
            });
            this.classList.add("filter-active");

            portfolioIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
          });
        });

        // Trigger layout after all images have loaded
        imagesLoaded(portfolioContainer, () => {
          portfolioIsotope.layout();
        });
      }
    };

    const initLightbox = () => {
      GLightbox({
        selector: ".glightbox",
      });
    };

    const initSlider = () => {
      new Swiper(".portfolio-details-slider", {
        speed: 400,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });
    };

    // Initialize Isotope
    initIsotope();

    // Initialize Lightbox
    initLightbox();

    // Initialize Slider
    initSlider();
  }, []);

  return (
    <div>
      <div
        className="page-title dark-background"
        style={{ backgroundImage: "url(assets/img/sub-hero-banner.jpg)" }}
      >
        <div className="container position-relative">
          <h1 className="works-banner">Projects</h1>
          <nav className="breadcrumbs">
            {/* <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Projects</li>
            </ol> */}
          </nav>
        </div>
      </div>

      <section id="projects" className="projects section">
        <div className="container">
          <div
            className="isotope-layout"
            data-default-filter="*"
            data-layout="masonry"
            data-sort="original-order"
          >
            <ul className="portfolio-filters isotope-filters">
              <li data-filter="*" className="filter-active">
                All
              </li>
              <li data-filter=".filter-remodeling">Residential</li>
              <li data-filter=".filter-construction">Interior</li>
              {/* <li data-filter=".filter-repairs">Industrial</li> */}
              <li data-filter=".filter-design">Housing</li>
            </ul>

            <div className="row gy-4 isotope-container">
              <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                <div className="portfolio-content h-100">
                  <img
                    src="assets/img/projects/repairs-1.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Branding 1</h4>
                    <p>Lorem ipsum, dolor sit amet consectetur</p>
                    <Link
                      to="assets/img/projects/repairs-1.jpg"
                      title="Branding 1"
                      data-gallery="portfolio-gallery-branding"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                <div className="portfolio-content h-100">
                  <img
                    src="assets/img/projects/remodeling-3.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>House</h4>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                <div className="portfolio-content h-100">
                  <img
                    src="assets/img/Interior/HEADER.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>VISVAJA</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/interior1/int1-2.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>SANTHOSH'S RESIDENCE</h4>
                    <Link
                      to="assets/img/projects/construction-1.jpg"
                      title="Product 1"
                      data-gallery="portfolio-gallery-product"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                <div className="portfolio-content h-100">
                  <img
                    src="assets/img/projects/repairs-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Branding 2</h4>
                    <p>Lorem ipsum, dolor sit amet consectetur</p>
                    <Link
                      to="assets/img/projects/repairs-2.jpg"
                      title="Branding 2"
                      data-gallery="portfolio-gallery-branding"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>

              {/* ----------------Residential images----------------------  */}
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling ">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/RES/render-01.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>UD YA NA</h4>
                    <Link
                      to="assets/img/projects/remodeling-1.jpg"
                      title="App 1"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/kudil/image1.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>KUDIL</h4>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                    <Link
                      to="assets/img/projects/remodeling-3.jpg"
                      title="App 3"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/residential2/res2-5.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>TEMPLE VIEW VILLA</h4>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                    <Link
                      to="assets/img/projects/remodeling-3.jpg"
                      title="App 3"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/residential3/res3-2.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>ANGEL DWELL</h4>
                 
                    <Link
                      to="assets/img/projects/remodeling-3.jpg"
                      title="App 3"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/residential4/res4-1.png"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>FARM VILLA</h4>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                    <Link
                      to="assets/img/projects/remodeling-2.jpg"
                      title="App 2"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/residential5/res5-HEADER.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>PALACODE</h4>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                    <Link
                      to="assets/img/projects/remodeling-3.jpg"
                      title="App 3"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/residential6/res6-2.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>LAXMI NIVAS</h4>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                    <Link
                      to="assets/img/projects/remodeling-3.jpg"
                      title="App 3"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/residential7/res7-3.png"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>GIRIJAS RESIDENCE</h4>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                    <Link
                      to="assets/img/projects/remodeling-3.jpg"
                      title="App 3"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <img
                    src="/assets/img/residential8/res8-6.jpg.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>INOU RESIDENCE</h4>
                    
                    <Link
                      to="assets/img/projects/remodeling-2.jpg"
                      title="App 2"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div> */}

              <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                <div className="portfolio-content h-100">
                  <img
                    src="assets/img/projects/repairs-3.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Branding 3</h4>
                    <p>Lorem ipsum, dolor sit amet consectetur</p>
                    <Link
                      to="assets/img/projects/repairs-3.jpg"
                      title="Branding 3"
                      data-gallery="portfolio-gallery-branding"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                <div className="portfolio-content h-100">
                  <img
                    src="assets/img/projects/repairs-3.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Branding 3</h4>
                    <p>Lorem ipsum, dolor sit amet consectetur</p>
                    <Link
                      to="assets/img/projects/repairs-3.jpg"
                      title="Branding 3"
                      data-gallery="portfolio-gallery-branding"
                      className="glightbox preview-link"
                    ></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                <div className="portfolio-content h-100">
                  <img
                    src="assets/img/projects/remodeling-2.jpg"
                    className="img-fluid gallery-img"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>House</h4>
                    {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer1 />
    </div>
  );
};

export default Projects;
