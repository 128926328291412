import React from 'react'
import { Link } from 'react-router-dom';


export const Footer = () => {
  return (
    <div>
      <footer id="footer" className="footer dark-background">

        <div className="container footer-top ">
          <div className="row gy-3">
            <div className="col-lg-4 col-md-6 footer-about">
              <Link to="index.html" className="logo d-flex align-items-center">
                <span className="sitename">VOGELKOP</span>
              </Link>
              <div className="footer-contact pt-3">
                <p>MIG - 462 , Old ASTC Hudco ,</p>
                <p>Hosur - 635 109 .</p>
                <p>27/G5 ,Kuttiyan St lane ,</p>
                <p>Kumbakonam - 612 001.</p>
                <p className="mt-3"><strong>Phone:</strong> <Link to="tel:+919488972821" style={{color:"white"}}>+91 94889 72821</Link> / <Link to="tel:+918124850138" style={{color:"white"}}>+91 81248 50138</Link></p>
                <p><strong>Email:</strong> <span><Link to="mailto:vogelkop.architects@gmail.com" style={{color:"white"}} target='blank'>vogelkop.architects@gmail.com</Link></span></p>

              </div>
             
            </div>

            <div className="col-lg-3 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About us</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-3 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>Construction</li>
                <li>Interiors</li>
                <li>Consulting</li>
                <li>Landscape</li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
            <h4>Social Links</h4>
            <div className="social-links d-flex mt-4">              
                <Link to=""><i className="bi bi-facebook"></i></Link>
                <Link to="https://www.instagram.com/vogelkop_architects?igsh=cHFqdTM0OGhqM3V2" target="blank"><i className="bi bi-instagram"></i></Link>
                <Link to=""><i className="bi bi-linkedin"></i></Link>
              </div>
              </div>

            

            

          </div>
        </div>

        <div className="container copyright text-center mt-4">
          <p>© <span>Copyright</span> <strong className="px-1 sitename">VOGELKOP</strong> <span>All Rights Reserved</span></p>
        </div>

      </footer>
    </div>
  )
}