import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Isotope from "isotope-layout";
import GLightbox from "glightbox";
import Swiper from "swiper";
import imagesLoaded from 'imagesloaded';
import {Footer} from '../navbar/Footer';
import Footer1 from '../navbar/Footer1';


const Press = () => {
  useEffect(() => {
    const initIsotope = () => {
      let portfolioContainer = document.querySelector(".isotope-container");
      if (portfolioContainer) {
        let portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: ".portfolio-item",
          layoutMode: "masonry",
        });

        let portfolioFilters = document.querySelectorAll(".portfolio-filters li");

        portfolioFilters.forEach((filter) => {
          filter.addEventListener("click", function (e) {
            e.preventDefault();
            portfolioFilters.forEach((el) => {
              el.classList.remove("filter-active");
            });
            this.classList.add("filter-active");

            portfolioIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
          });
        });

        // Trigger layout after all images have loaded
        imagesLoaded(portfolioContainer, () => {
          portfolioIsotope.layout();
        });
      }
    };

    const initLightbox = () => {
      GLightbox({
        selector: ".glightbox",
      });
    };

    const initSlider = () => {
      new Swiper(".portfolio-details-slider", {
        speed: 400,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });
    };

    // Initialize Isotope
    initIsotope();

    // Initialize Lightbox
    initLightbox();

    // Initialize Slider
    initSlider();
  }, []);

  return (
    <div>
      <div className="page-title dark-background " style={{ backgroundImage: 'url(assets/img/Bannerimages/pressheader.jpg)' }}>
        <div className="container position-relative">
          <h1 className="works-banner" >Press</h1>
          <nav className="breadcrumbs">
            {/* <ol>
              <li><Link to="/">Home</Link></li>
              <li className="current">Press</li>
            </ol> */}
          </nav>
        </div>
      </div>

      <section id="projects" className="projects section" style={{height:'50vh'}}>
        <div className="container">
          <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
            <ul className="portfolio-filters isotope-filters">
              {/* <li data-filter="*" className="filter-active">tab1</li> */}
              <li data-filter=".filter-remodeling">tab2</li>
              <li data-filter=".filter-construction">tab3</li>
              <li data-filter=".filter-repairs">tab4</li>
              <li data-filter=".filter-design">tab5</li>
            </ul>

            <div className="row gy-4 isotope-container">
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                  <p>lorem tab1 content how the dtc bn vn kjsnk nv cnszdkmi </p>
                  
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                <div className="portfolio-content h-100">
                <p>lorem tab2 content</p>
                  
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                <div className="portfolio-content h-100">
                <p>lorem tab3 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                <div className="portfolio-content h-100">
                <p>lorem tab4 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                <p>lorem tab1 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                <div className="portfolio-content h-100">
                <p>lorem tab2 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                <div className="portfolio-content h-100">
                <p>lorem tab3 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                <div className="portfolio-content h-100">
                <p>lorem tab4 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                <div className="portfolio-content h-100">
                <p>lorem tab1 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-construction">
                <div className="portfolio-content h-100">
                <p>lorem tab2 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-repairs">
                <div className="portfolio-content h-100">
                <p>lorem tab3 content</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-design">
                <div className="portfolio-content h-100">
                <p>lorem tab4 content</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
<Footer1/>


    </div>
  )
}

export default Press