import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });

    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");

      if (!selectHeader) {
        console.error("Header element not found");
        return;
      }

      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      ) {
        return;
      }

      window.scrollY > 100
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
    };

    window.addEventListener("scroll", toggleScrolled);
    toggleScrolled();

    const scrollTop = document.querySelector(".scroll-top");
    if (scrollTop) {
      const toggleScrollTop = () => {
        window.scrollY > 100
          ? scrollTop.classList.add("active")
          : scrollTop.classList.remove("active");
      };

      scrollTop.addEventListener("click", (e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

      window.addEventListener("scroll", toggleScrollTop);
      window.addEventListener("load", toggleScrollTop);
    }

    return () => {
      window.removeEventListener("scroll", toggleScrolled);
    };
  }, []);

  useEffect(() => {
    const carouselElement = document.querySelector("#hero-carousel");
    const carousel = new window.bootstrap.Carousel(carouselElement, {
      interval: 4000,
      ride: "carousel",
      pause: "false",
    });
  }, []);

  return (
    <div>
      <section id="hero" className="hero section dark-background">
        <div className="hero_content">
          <div data-aos="fade-up" data-aos-delay="100">
            <h1>Vision Ahead</h1>
            <div className="explore">
              <p>
                <Link to="/works">
                  <button>EXPLORE</button>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div
          id="hero-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-pause="false"
        >
          <div className="carousel-item active">
            <img src="assets/img/hero-carousel/banner2.jpg" alt="" />
          </div>
          <div className="carousel-item">
            <img src="assets/img/hero-carousel/banner1.jpeg" alt="" />
          </div>
          <div className="carousel-item">
            <img src="assets/img/hero-carousel/banner3.jpg" alt="" />
          </div>
          <div className="carousel-item">
            <img src="assets/img/hero-carousel/banner4.jpg" alt="" />
          </div>
          <div className="carousel-item">
            <img src="assets/img/hero-carousel/banner5.jfif" alt="" />
          </div>
          <Link
            className="carousel-control-prev"
            to="#hero-carousel"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bi bi-chevron-left"
              aria-hidden="true"
            ></span>
          </Link>
          <Link
            className="carousel-control-next"
            to="#hero-carousel"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bi bi-chevron-right"
              aria-hidden="true"
            ></span>
          </Link>
        </div>
        <img src="assets/img/logo.png" alt="Logo" className="banner-img" />
      </section>
      <Footer1 />
    </div>
  );
};

export default Home;
