import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import Header from "./components/navbar/Header";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Works from "./components/pages/Works";
import Projects from "./components/pages/Projects";
import Blog from "./components/pages/Blog";
import Team from "./components/about/Team";
import Press from "./components/about/Press";
import Philosophy from "./components/about/Philosophy";
import Residential from "./components/works/Residential";
import Residential1 from "./components/works/Residantial1";
import Residential2 from "./components/works/Residential2";
import Residential3 from "./components/works/Residential3";
import Residential4 from "./components/works/Residential4";
import Residential5 from "./components/works/Residential5";
import Residential6 from "./components/works/Residential6";
import Residential7 from "./components/works/Residential7";
import Residential8 from "./components/works/Residential8";
import Interior1 from "./components/works/Interior1";
import Interior2 from "./components/works/Interior2";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/works" element={<Works />}></Route>

          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/team" element={<Team />}></Route>
          <Route path="/press" element={<Press />}></Route>
          <Route path="/philosophy" element={<Philosophy />}></Route>

          <Route path="/Residential" element={<Residential />}></Route>
          <Route path="/Residential1" element={<Residential1 />}></Route>
          <Route path="/Residential2" element={<Residential2 />}></Route>
          <Route path="/Residential3" element={<Residential3 />}></Route>
          <Route path="/Residential4" element={<Residential4 />}></Route>
          <Route path="/Residential5" element={<Residential5 />}></Route>
          <Route path="/Residential6" element={<Residential6 />}></Route>
          <Route path="/Residential7" element={<Residential7 />}></Route>
          <Route path="/Residential8" element={<Residential8 />}></Route>
          <Route path="/Interior1" element={<Interior1 />}></Route>
          <Route path="/Interior2" element={<Interior2 />}></Route>

          {/* <Route path='/Blog' element={<Blog/>}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
