import React, { useEffect, useState } from "react";
import {CreateContact} from "../../Apiservice"
import { Link } from 'react-router-dom';
import {Footer} from '../navbar/Footer';



const Contact = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      mobile: "",
      subject: "",
      message: "",
    });
  
    // useEffect(() => {
    //   document.title = "Contact us  | German Bakes";
    //   document
    //     .querySelector("meta[name='description']")
    //     .setAttribute(
    //       "content",
    //       "Contact us Feel free to contact us with any questions or concerns."
    //     );
    // }, []);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await CreateContact(formData);
        console.log("Form submitted successfully:", response);
        alert("Form submitted successfully!");
  
        // Clear the form
        setFormData({
          name: "",
          mobile: "",
          email: "",
          subject: "",
          message: "",
        });
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        alert("There was an error submitting the form.");
      }
    };
  return (
    <div>
      <div className="page-title dark-background" style={{backgroundImage: 'url(assets/img/Bannerimages/Contact.jpg)'}}>
        <div className="container position-relative">
          <h1 className="works-banner" >Contact</h1>
          <nav className="breadcrumbs">
            {/* <ol>
              <li><Link to="/">Home</Link></li>
              <li className="current">Contact</li>
            </ol> */}
          </nav>
        </div>
      </div>

      <section id="contact" className="contact section">
        <div className="container">
        <div className="row gy-4">

<div className="col-lg-6">
  <div className="info-item d-flex flex-column justify-content-center align-items-center" >
    <i className="bi bi-geo-alt"></i>
    <h3>Address</h3>
    <p>MIG - 462 , Old ASTC Hudco ,
    Hosur - 635 109 .</p>
    <p>27/G5 ,Kuttiyan St lane ,
    Kumbakonam - 612 001 .</p>
  </div>
</div>

<div className="col-lg-3 col-md-6">
  <div className="info-item d-flex flex-column justify-content-center align-items-center" >
    <i className="bi bi-telephone"></i>
    <h3>Call Us</h3>
    <p><Link to="tel:+919488972821">+91 94889 72821</Link> / <Link to="tel:+918124850138">+91 81248 50138</Link></p>

  </div>
</div>

<div className="col-lg-3 col-md-6">
  <div className="info-item d-flex flex-column justify-content-center align-items-center" >
    <i className="bi bi-envelope"></i>
    <h3>Email Us</h3>
    <p><Link to="mailto:vogelkop.architects@gmail.com" target='blank'>vogelkop.architects@gmail.com</Link></p>
  </div>
</div>

</div>

<div className="row gy-4 mt-1">
<div className="col-lg-6" >
  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15566.644520326765!2d77.8193351!3d12.735516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae7110a65f0505%3A0x235b21d049e35c2!2sVogelkop%20Architects!5e0!3m2!1sen!2sin!4v1720089674719!5m2!1sen!2sin" frameborder="0" style={{width:'100%',height:'400px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

<div className="col-lg-6">
  <form action="forms/contact.php" method="post" 
  className="php-email-form"
  onSubmit={handleSubmit} >
    <div className="row">
              <div className="col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control"
                name="subject"
                id="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control"
                name="mobile"
                id="mobile"
                placeholder="Contact Number"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mt-3">
              <textarea
                className="form-control"
                name="message"
                rows="5"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="my-3">
              <div className="loading">Loading</div>
              <div className="error-message"></div>
              <div className="sent-message">
                Your message has been sent. Thank you!
              </div>
            </div>
            <div className="text-center">
              <button type="submit">Send Message</button>
            </div>
          </form>
</div>
</div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default Contact;
